
import { Layout } from "@/components/layout/Layout";
import { Card } from "@/components/ui/card";
import { Mail, User, Calendar, Gift, Heart, LogIn, UserPlus, Info, Sparkles, Share2, Shield, FileText, Bot, AtSign, HelpCircle, Rocket, Star, Coffee, Bell } from "lucide-react";
import { useAuth } from "@/hooks/useAuth";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { FeedList } from "@/components/feed/FeedList";
import { format } from "date-fns";
import { InterestsList } from "@/components/interests/InterestsList";
import { useToast } from "@/hooks/use-toast";
import { Link } from "react-router-dom";
import { CookieSettingsButton } from "@/components/CookieBanner";
import { LanguageToggle } from "@/components/LanguageToggle";
import { useLanguage } from "@/contexts/LanguageContext";
import { useEffect, useState } from "react";

const BackgroundImage = () => {
  return (
    <div 
      className="absolute inset-0 bg-[#F1F0FB]"
      aria-hidden="true"
    >
      <img 
        src="/photo-1549465220-1a8b9238cd48.avif" 
        alt="" 
        className="w-full h-full object-cover opacity-10"
        fetchPriority="high"
        decoding="async"
        loading="eager"
      />
    </div>
  );
};

const Index = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { translate } = useLanguage();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = "/photo-1549465220-1a8b9238cd48.avif";
    img.onload = () => setImageLoaded(true);
    return () => {
      img.onload = null;
    };
  }, []);

  const handleCopyNickname = async () => {
    if (user?.nickname) {
      try {
        await navigator.clipboard.writeText(user.nickname);
        toast({
          title: "Copied!",
          description: "Nickname copied to clipboard",
        });
      } catch (err) {
        toast({
          title: "Failed to copy",
          description: "Could not copy nickname to clipboard",
          variant: "destructive",
        });
      }
    }
  };

  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#F1F0FB] to-[#E5DEFF]">
        <div className="fixed right-4 bottom-20 z-[9999]">
          <CookieSettingsButton compact={true} />
        </div>
        <div className="fixed right-4 top-4 z-[9999]">
          <LanguageToggle />
        </div>
        <div className="relative overflow-hidden">
          <BackgroundImage />
          <div className="max-w-6xl mx-auto pt-20 pb-16 px-4 sm:px-6 lg:px-8 relative">
            <div className="text-center space-y-8 animate-fade-in">
              <h1 className="text-5xl md:text-6xl font-bold bg-gradient-to-r from-[#9b87f5] via-[#D946EF] to-[#F97316] bg-clip-text text-transparent leading-relaxed pb-2">
                {translate("home.hero.title")}
              </h1>
              <p className="text-xl md:text-2xl text-[#403E43] max-w-2xl mx-auto">
                {translate("home.hero.subtitle")}
              </p>
              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <Button
                  onClick={() => navigate('/register')}
                  className="group relative px-8 py-6 text-lg bg-gradient-to-r from-[#9b87f5] to-[#D946EF] hover:opacity-90 transition-all"
                >
                  <span className="relative z-10 flex items-center justify-center">
                    <Rocket className="mr-2 h-5 w-5" />
                    {translate("button.signup")}
                  </span>
                </Button>
                <Button
                  variant="outline"
                  onClick={() => navigate('/login')}
                  className="px-8 py-6 text-lg border-2 border-[#9b87f5] text-[#7E69AB] hover:bg-[#9b87f5]/10"
                >
                  <LogIn className="mr-2 h-5 w-5" />
                  {translate("button.signin")}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white/40 backdrop-blur-sm py-16">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold mb-12 text-[#1A1F2C]">{translate("home.section.why")}</h2>
            
            <div className="grid md:grid-cols-3 gap-8 mb-8">
              <Card className="p-8 hover:scale-105 transition-transform duration-300 bg-white/80 backdrop-blur-sm border-none shadow-lg">
                <div className="rounded-full bg-gradient-to-r from-[#9b87f5] to-[#D946EF] w-16 h-16 flex items-center justify-center mb-6 mx-auto">
                  <Gift className="h-8 w-8 text-white" />
                </div>
                <h2 className="text-2xl font-bold mb-4 text-[#1A1F2C]">{translate("home.feature.wishlist.title")}</h2>
                <p className="text-[#8E9196] text-lg">{translate("home.feature.wishlist.description")}</p>
              </Card>

              <Card className="p-8 hover:scale-105 transition-transform duration-300 bg-white/80 backdrop-blur-sm border-none shadow-lg">
                <div className="rounded-full bg-gradient-to-r from-[#D946EF] to-[#F97316] w-16 h-16 flex items-center justify-center mb-6 mx-auto">
                  <Star className="h-8 w-8 text-white" />
                </div>
                <h2 className="text-2xl font-bold mb-4 text-[#1A1F2C]">{translate("home.feature.ai.title")}</h2>
                <p className="text-[#8E9196] text-lg">{translate("home.feature.ai.description")}</p>
              </Card>

              <Card className="p-8 hover:scale-105 transition-transform duration-300 bg-white/80 backdrop-blur-sm border-none shadow-lg">
                <div className="rounded-full bg-gradient-to-r from-[#F97316] to-[#9b87f5] w-16 h-16 flex items-center justify-center mb-6 mx-auto">
                  <Share2 className="h-8 w-8 text-white" />
                </div>
                <h2 className="text-2xl font-bold mb-4 text-[#1A1F2C]">{translate("home.feature.social.title")}</h2>
                <p className="text-[#8E9196] text-lg">{translate("home.feature.social.description")}</p>
              </Card>
            </div>
            
            <div className="grid md:grid-cols-3 gap-8 mb-16">
              <Card className="p-8 hover:scale-105 transition-transform duration-300 bg-white/80 backdrop-blur-sm border-none shadow-lg">
                <div className="rounded-full bg-gradient-to-r from-[#D946EF] to-[#9b87f5] w-16 h-16 flex items-center justify-center mb-6 mx-auto">
                  <Heart className="h-8 w-8 text-white" />
                </div>
                <h2 className="text-2xl font-bold mb-4 text-[#1A1F2C]">{translate("home.feature.wanted.title")}</h2>
                <p className="text-[#8E9196] text-lg">{translate("home.feature.wanted.description")}</p>
              </Card>
              
              <Card className="p-8 hover:scale-105 transition-transform duration-300 bg-white/80 backdrop-blur-sm border-none shadow-lg">
                <div className="rounded-full bg-gradient-to-r from-[#3B82F6] to-[#F97316] w-16 h-16 flex items-center justify-center mb-6 mx-auto">
                  <Bell className="h-8 w-8 text-white" />
                </div>
                <h2 className="text-2xl font-bold mb-4 text-[#1A1F2C]">{translate("home.feature.price.title")}</h2>
                <p className="text-[#8E9196] text-lg">{translate("home.feature.price.description")}</p>
              </Card>
              
              <Card className="p-8 hover:scale-105 transition-transform duration-300 bg-white/80 backdrop-blur-sm border-none shadow-lg">
                <div className="rounded-full bg-gradient-to-r from-[#F97316] to-[#D946EF] w-16 h-16 flex items-center justify-center mb-6 mx-auto">
                  <Sparkles className="h-8 w-8 text-white" />
                </div>
                <h2 className="text-2xl font-bold mb-4 text-[#1A1F2C]">{translate("home.feature.recommendation.title")}</h2>
                <p className="text-[#8E9196] text-lg">{translate("home.feature.recommendation.description")}</p>
              </Card>
            </div>
          </div>
        </div>

        <div className="max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:px-8 text-center">
          <nav className="flex flex-wrap justify-center gap-8 mb-16">
            <Link
              to="/about"
              className="flex items-center gap-2 text-[#7E69AB] hover:text-[#9b87f5] transition-colors"
            >
              <Info className="h-5 w-5" />
              <span>{translate("nav.about")}</span>
            </Link>
            <Link
              to="/contact"
              className="flex items-center gap-2 text-[#7E69AB] hover:text-[#9b87f5] transition-colors"
            >
              <Mail className="h-5 w-5" />
              <span>{translate("nav.contact")}</span>
            </Link>
            <Link
              to="/faq"
              className="flex items-center gap-2 text-[#7E69AB] hover:text-[#9b87f5] transition-colors"
            >
              <HelpCircle className="h-5 w-5" />
              <span>{translate("nav.faq")}</span>
            </Link>
            <Link
              to="/privacy-policy"
              className="flex items-center gap-2 text-[#7E69AB] hover:text-[#9b87f5] transition-colors"
            >
              <Shield className="h-5 w-5" />
              <span>{translate("nav.privacy")}</span>
            </Link>
            <Link
              to="/terms-of-service"
              className="flex items-center gap-2 text-[#7E69AB] hover:text-[#9b87f5] transition-colors"
            >
              <FileText className="h-5 w-5" />
              <span>{translate("nav.terms")}</span>
            </Link>
            <Link
              to="/impressum"
              className="flex items-center gap-2 text-[#7E69AB] hover:text-[#9b87f5] transition-colors"
            >
              <FileText className="h-5 w-5" />
              <span>{translate("nav.impressum")}</span>
            </Link>
          </nav>

          <Card className="p-12 bg-gradient-to-r from-[#9b87f5] to-[#D946EF] border-none shadow-xl">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
              {translate("home.cta.title")}
            </h2>
            <p className="text-white/90 text-xl mb-8 max-w-2xl mx-auto">
              {translate("home.cta.subtitle")}
            </p>
            <Button
              onClick={() => navigate('/register')}
              variant="secondary"
              className="px-8 py-6 text-lg bg-white text-[#9b87f5] hover:bg-white/90"
            >
              <Rocket className="mr-2 h-5 w-5" />
              {translate("button.signup")}
            </Button>
          </Card>

          <div className="mt-12 text-center text-sm text-[#8E9196]">
            {translate("footer.copyright")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <div className="space-y-6">
        <Card className="p-6 bg-gradient-to-br from-[#F1F0FB] to-white shadow-lg animate-fade-in border border-[#9b87f5]/20">
          <h2 className="font-semibold mb-6 text-[#1A1F2C] text-xl">Profile</h2>
          <div className="space-y-4">
            <div className="flex items-center gap-4 p-4 rounded-lg bg-white/50 backdrop-blur-sm transition-all hover:bg-white/80">
              <div className="p-3 rounded-full bg-[#9b87f5]/10">
                <User className="h-6 w-6 text-[#7E69AB]" />
              </div>
              <div>
                <p className="text-sm text-[#8E9196]">Full Name</p>
                <p className="text-[#1A1F2C]">{user.full_name}</p>
              </div>
            </div>

            <div className="flex items-center gap-4 p-4 rounded-lg bg-white/50 backdrop-blur-sm transition-all hover:bg-white/80 cursor-pointer group" onClick={handleCopyNickname}>
              <div className="p-3 rounded-full bg-[#9b87f5]/10">
                <AtSign className="h-6 w-6 text-[#7E69AB]" />
              </div>
              <div className="flex-grow">
                <p className="text-sm text-[#8E9196]">Nickname</p>
                <p className="text-[#1A1F2C] group-hover:text-[#9b87f5] transition-colors">{user.nickname}</p>
              </div>
              <span className="text-sm text-[#8E9196] opacity-0 group-hover:opacity-100 transition-opacity">Click to copy</span>
            </div>

            <div className="flex items-center gap-4 p-4 rounded-lg bg-white/50 backdrop-blur-sm transition-all hover:bg-white/80">
              <div className="p-3 rounded-full bg-[#9b87f5]/10">
                <Mail className="h-6 w-6 text-[#7E69AB]" />
              </div>
              <div>
                <p className="text-sm text-[#8E9196]">Email</p>
                <p className="text-[#1A1F2C]">{user.email}</p>
              </div>
            </div>

            {user.birthday && (
              <div className="flex items-center gap-4 p-4 rounded-lg bg-white/50 backdrop-blur-sm transition-all hover:bg-white/80">
                <div className="p-3 rounded-full bg-[#9b87f5]/10">
                  <Calendar className="h-6 w-6 text-[#7E69AB]" />
                </div>
                <div>
                  <p className="text-sm text-[#8E9196]">Birthday</p>
                  <p className="text-[#1A1F2C]">
                    {format(new Date(user.birthday), 'MMMM dd, yyyy')}
                  </p>
                </div>
              </div>
            )}
          </div>
        </Card>

        {user.id && <InterestsList userId={user.id} />}

        <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
          {user.id && <FeedList userId={user.id} />}
        </div>
      </div>
    </Layout>
  );
};

export default Index;
